@import '@socialbrothers/assets/styles/abstracts';

.Steps {
	margin-bottom: px(2);

	&--None {
		font-weight: $font-weight-bold;
	}

	&__Item {
		margin-bottom: px(1);
	}
}

.Step {
	border: 1px solid $input-border-color;
	border-left: 4px solid color(primary);
	border-radius: $input-radius;
	background-color: color(white);

	&__Header {
		display: flex;
		align-items: center;

		padding: px(1) px(1.5);
		border-bottom: 1px solid $input-border-color;
	}

	&__Title {
		margin-right: px(2);
		margin-bottom: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		small {
			display: block;
			margin-top: px(0.5);
			font-weight: $font-weight-normal;
		}
	}

	&__Edit {
		font-size: $font-size-sm;
		color: color(primary);
		margin-right: px(1);
	}

	&__Buttons {
		margin-left: auto;
		white-space: nowrap;
	}

	&__Effects {
		padding: px(2) px(1.5);
	}

	&--Score {
		border-left: 4px solid color(warning);
	}

	&--Content {
		border-left: 4px solid color(secondary);
	}
}
