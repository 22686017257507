@import '@socialbrothers/assets/styles/abstracts';

.Sections {
	&--None {
		margin-bottom: px(2);
		font-weight: $font-weight-bold;
	}

	&__Item {
		margin-bottom: px(2);
	}
}

.Section {
	border: 1px solid $input-border-color;
	border-radius: $input-radius;
	background-color: $input-border-color;

	&__Header {
		display: flex;
		align-items: center;

		padding: px(1) px(1.5);
	}

	&__Title {
		margin-right: px(2);
		margin-bottom: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__Steps {
		padding: 0 px(1.5) px(2) px(1.5);
	}

	&__Buttons {
		margin-left: auto;
		white-space: nowrap;
	}
}
