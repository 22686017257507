.react-select {

	div[class*="-control"] {
		border: 0;
		outline: none;
		border-color: transparent;
		box-shadow: none;
		padding: 6px px(2);

		> div {
			&:first-child {
				padding: 0;
			}

			&:last-child {
				display: none;
			}
		}
	}

	div[class*="-indicatorSeparator"],
	div[class*="-indicatorContainer"] {
		display: none !important;
	}

	div[class*="-2-listbox"] {
		z-index: 1001;
	}
}