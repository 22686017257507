@import '@socialbrothers/assets/styles/styles.scss';

.FilterWrapper {
	position: relative;
	margin-top: px(3);
	margin-bottom: $default-margin;

	.Toggle {
		position: absolute;
		background-color: color(gray, 200);
		z-index: 1;
		white-space: nowrap;
		top: 0;
		left: 50%;
		border-radius: $border-radius;
		padding: px(0.25) px(2);
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		cursor: pointer;
		user-select: none;
		transform: translate(-50%, -50%);
	}

	.Filter {
		padding: px(4) px(2) 0 px(2);
		border: 1px solid color(gray, 200);
		position: relative;
		border-radius: $border-radius;

		&--Hidden {
			height: 1px;
			padding: 0;
			margin-bottom: px(4);
			border-bottom: 0;
			overflow: hidden;
		}

		&__Row {
			position: relative;
		}

		&__Delete {
			margin-top: px(1);
			color: color(danger);
			line-height: 48px;
			cursor: pointer;
			text-align: center;
			border-radius: $border-radius;

			@include media-breakpoint-only(xs) {
				margin-top: px(-1);
				margin-bottom: px(2);
				background-color: color(danger);
				color: color(white);
			}
		}
	}
}
