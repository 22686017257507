@import '@socialbrothers/assets/styles/abstracts';

.Tooltip {
	position: fixed;
	padding: px(1);
	border-radius: $border-radius;
	background: color(black);
	box-shadow: $box-shadow;
	color: white;
	font-size: $font-size-xs;
	text-align: center;

	&::after {
		content: "";    
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-width: 5px 5px 0 5px;
		border-style: solid;
		border-color: color(black) transparent transparent transparent;
	}
}