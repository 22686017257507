@import '@socialbrothers/assets/styles/styles.scss';

.Wrapper {
	width: 100%;
}

.TableHeader {
	display: flex;
	align-items: flex-start;
	margin-bottom: $default-margin;

	&__Search {
		width: 100%;
		margin-right: $default-margin;
	}

	&__Add {
		margin-left: auto;
	}
}

.TableBody {
	overflow-x: scroll;
	border-radius: $border-radius;

	.Sortable {
		display: flex;
		cursor: pointer;

		&__Icon {
			margin-left: auto;
			padding-left: px(2);
		}
	}

	.Empty {
		padding-top: px(2);
		padding-bottom: px(2);
		font-weight: $font-weight-bold;
		text-align: center;
	}

	table {
		width: 100%;
		overflow: hidden;
		border-radius: $border-radius;

		thead,
		tbody {
			tr {
				td,
				th {
					padding: px(0.5) px(1.5);
					text-align: left;
				}

				th {
					padding-top: px(1);
					padding-bottom: px(1);
					white-space: nowrap;
					user-select: none;
				}
			}
		}

		thead {
			tr {
				border: 1px solid color(primary);
				border-bottom: 0;
				background-color: color(primary, light);

				th {
					color: color(white);

					&:last-child {
						border-right: 0;
					}
				}
			}

			th {
				border-right: 1px solid color(primary);
			}
		}

		tbody {
			tr {
				border: 1px solid color(gray, 200);
				border-top: 0;
				background-color: color(gray, 100);

				&:nth-child(odd) {
					background-color: color(white);
				}

				&:hover {
					td {
						background-color: color(gray, 200);
					}
				}
			}

			td {
				border-right: 1px solid color(gray, 200);
				white-space: nowrap;
			}
		}
	}
}

.TableFooter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: $default-margin;

	.PerPage {
		display: flex;
		align-items: center;
		white-space: nowrap;

		select {
			margin: 0 px(1);
			padding: px(1);
			text-align: center;
		}

		&__Current {
			margin-left: px(3);
		}
	}

	.Results {
		margin-left: px(2);
		opacity: 0.5;
	}

	.Pagination {
		margin-left: auto;
	}

	@include media-breakpoint-only(xs) {
		flex-direction: column-reverse;
		align-items: flex-start;

		.Pagination {
			margin-bottom: $default-margin;
		}
	}
}
