@import '@socialbrothers/assets/styles/abstracts';

.Effect {
	align-items: center;
	margin-bottom: px(2);

	@include media-breakpoint-down(md) {
		flex-direction: column;
		align-items: flex-start;
		margin: 0;

		.Effect__Item {
			margin-bottom: px(1);
		}

		.Effect__Type,
		.Effect__Step,
		.Effect__Section {
			width: 100%;
			margin: 0 0 px(2) 0;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__Item {
		display: flex;
		flex: 2;
		font-weight: $font-weight-bold;
	}

	&__Type,
	&__Step,
	&__Section {
		margin-bottom: 0;
	}
}
