@import '@socialbrothers/assets/styles/abstracts';

.SelectInput {
	position: relative;
	width: 100%;

	.Icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: px(2);
		pointer-events: none;
	}

	.Input {
		display: none;
	}

	.ReactSelect {
		&-control {
			border: 0;
		}
	}
}

