// 1. Settings
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$theme-colors: (
	primary: (
		default: #183153,
		dark: darken(#183153, 10%),
		light: lighten(#183153, 5%),
	),
	secondary: (
		default: #56bbad,
		dark: darken(#56bbad, 5%),
		light: lighten(#56bbad, 5%),
	),
	tertiary: (
		default: #69548d,
		dark: darken(#69548d, 5%),
		light: lighten(#69548d, 5%),
	),
	gray: (
		default: #858796,
		dark: darken(#858796, 5%),
		light: lighten(#858796, 5%),
		100: #f8f9fc,
		200: #eaecf4,
		300: #dddfeb,
		400: #d1d3e2,
		500: #b7b9cc,
		600: #858796,
		700: #6e707e,
		800: #5a5c69,
		900: #3a3b45,
	),
	black: (
		default: #2e384d,
	),
	white: (
		default: #ffffff,
	),
	success: (
		default: #1cc88a,
		dark: darken(#1cc88a, 5%),
		light: lighten(#1cc88a, 5%),
	),
	warning: (
		default: #f6c23e,
		dark: darken(#f6c23e, 5%),
		light: lighten(#f6c23e, 5%),
	),
	danger: (
		default: #e74a3b,
		dark: darken(#e74a3b, 5%),
		light: lighten(#e74a3b, 5%),
	),
	info: (
		default: #36b9cc,
		dark: darken(#36b9cc, 5%),
		light: lighten(#36b9cc, 5%),
	),
);

$timings: (
	xs: 0.1s,
	sm: 0.15s,
	md: 0.2s,
	lg: 0.3s,
	xl: 0.4s,
);

$transitions: (
	xs: timing(xs) ease-in-out,
	sm: timing(sm) ease-in-out,
	md: timing(md) cubic-bezier(0.9, 0.1, 0.1, 0.9),
	lg: timing(lg) ease-in-out,
	xl: timing(xl) cubic-bezier(0.9, 0.1, 0.1, 0.9),
);

$spacings: (
	none: 0,
	xs: px(1),
	sm: px(2),
	md: px(4),
	lg: px(6),
	xl: px(10),
);

// 2. Options
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$body-color: color(black);
$body-background: #fafbff;

$border-radius: px(0.5);
$default-margin: px(2);
$default-padding: 20px;

$box-shadow: none;
$box-shadow-overlay: 0px 0 5px 0px rgba(0, 0, 0, 0.2);

// 2.1 Typography
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;800&display=swap');

$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$font-family-monospace: 'Courier New', Courier, monospace;
$font-family-base: $font-family-sans-serif;
$font-family-icons: 'Font Awesome 5 Pro';

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-base: 16px;
$font-weight-base: $font-weight-regular;
$line-height-base: 1.5;
$font-family-base: $font-family-base;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xxl: 30px;

$headings-font-family: $font-family-sans-serif;
$headings-color: color(black);
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.3;
$headings-margin-bottom: px(1);

$font-size-h1: 30px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$paragraph-margin: $default-margin;
$paragraph-lead-font-size: $font-size-md * 1.5;
$paragraph-lead-margin-bottom: $paragraph-margin;

$link-color: color(secondary);
$link-decoration: underline;
$link-hover-color: color(secondary, dark);
$link-hover-decoration: underline;

$list-margin: $default-margin;
$list-item-margin: px(0.5);

$blockquote-margin: px(3) 0 px(7) 0;
$blockquote-padding: px(4) px(3);

// 2.2 Buttons
$button-font-weight: $font-weight-regular;
$button-font-color: color(white);
$button-border-radius: $border-radius;

$button-sm-padding: 4px 6px;
$button-padding: 8px 10px;
$button-lg-padding: 8px 20px;

$button-sm-font-size: $font-size-sm;
$button-font-size: $font-size-base;
$button-lg-font-size: $font-size-lg;

// 2.3 Forms
$input-font-size: $font-size-md;
$input-label-font-size: $font-size-base;
$input-background-color: color(white);
$input-active-color: color(primary, 300);
$input-radius: $border-radius;
$input-border-color: color(gray, 200);
$input-invalid-color: color(danger);
$input-placeholder-color: color(black);
$input-radio-checkbox: color(primary);

// 2.4 Sidebar
$body-spacing: 30px;
$sidebar-spacing: px(2);
