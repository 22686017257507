@import '@socialbrothers/assets/styles/abstracts';

.Button {
	padding-right: 2px;
	padding-left: 2px;
	border: 0 !important;
	background-color: transparent !important;
	color: color(primary);

	&:hover {
		color: color(primary) !important;
	}

	&:focus {
		outline: 0;
		box-shadow: 0;
	}
}
