@each $size, $value in $spacings {
	.mtb-#{$size} {
		margin-top: $value / 2;
		margin-bottom: $value / 2;
	}

	.mt-#{$size} {
		margin-top: $value;
	}

	.mb-#{$size} {
		margin-bottom: $value;
	}

	.ml-#{$size} {
		margin-left: $value;
	}

	.mr-#{$size} {
		margin-right: $value;
	}

	.pt-#{$size} {
		padding-top: $value;
	}

	.pb-#{$size} {
		padding-bottom: $value;
	}

	.pl-#{$size} {
		padding-left: $value;
	}

	.pr-#{$size} {
		padding-right: $value;
	}

	@include media-breakpoint-down(lg) {
		.mtb-lg-#{$size} {
			margin-top: $value / 2;
			margin-bottom: $value / 2;
		}

		.mt-lg-#{$size} {
			margin-top: $value;
		}

		.mb-lg-#{$size} {
			margin-bottom: $value;
		}

		.ml-lg-#{$size} {
			margin-left: $value;
		}

		.mr-lg-#{$size} {
			margin-right: $value;
		}

		.pt-lg-#{$size} {
			padding-top: $value;
		}

		.pb-lg-#{$size} {
			padding-bottom: $value;
		}

		.pl-lg-#{$size} {
			padding-left: $value;
		}

		.pr-lg-#{$size} {
			padding-right: $value;
		}
	}

	@include media-breakpoint-down(md) {
		.mtb-md-#{$size} {
			margin-top: $value / 2;
			margin-bottom: $value / 2;
		}

		.mt-md-#{$size} {
			margin-top: $value;
		}

		.mb-md-#{$size} {
			margin-bottom: $value;
		}

		.ml-md-#{$size} {
			margin-left: $value;
		}

		.mr-md-#{$size} {
			margin-right: $value;
		}

		.pt-md-#{$size} {
			padding-top: $value;
		}

		.pb-md-#{$size} {
			padding-bottom: $value;
		}

		.pl-md-#{$size} {
			padding-left: $value;
		}

		.pr-md-#{$size} {
			padding-right: $value;
		}
	}

	@include media-breakpoint-down(sm) {
		.mtb-sm-#{$size} {
			margin-top: $value / 2;
			margin-bottom: $value / 2;
		}

		.mt-sm-#{$size} {
			margin-top: $value;
		}

		.mb-sm-#{$size} {
			margin-bottom: $value;
		}

		.ml-sm-#{$size} {
			margin-left: $value;
		}

		.mr-sm-#{$size} {
			margin-right: $value;
		}

		.pt-sm-#{$size} {
			padding-top: $value;
		}

		.pb-sm-#{$size} {
			padding-bottom: $value;
		}

		.pl-sm-#{$size} {
			padding-left: $value;
		}

		.pr-sm-#{$size} {
			padding-right: $value;
		}
	}
}
