@import '@socialbrothers/assets/styles/abstracts';

.Page {
	display: flex;
	min-height: 100vh;
}

.Body {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-x: hidden;

	.Content {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: $body-spacing;
	}

	.Header {
		padding: 0 $body-spacing;
		display: flex;
		align-items: flex-start;

		&__Content {
			.Title {
				margin-top: $body-spacing;
			}
		}

		&__Buttons {
			margin-left: auto;
			margin-top: $body-spacing;
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;

			.Header__Buttons {
				margin-left: 0;
				margin-top: $default-margin;
			}
		}
	}

	.Content,
	.Header .Header__Content {
		> *:last-child {
			margin-bottom: 0;
		}
	}
}
